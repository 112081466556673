import styles from '../../styles/features/videoContainer.module.css'

function VideoContainer({}) {
  return (
    <div className={styles.main}>
      <video 
        src={"https://shorecastsiosscreenrecording.s3.us-east-1.amazonaws.com/ScreenRecordingscios.mp4"}
        autoPlay 
        loop 
        muted
        playsInline
        className={styles.vid}
      />
    </div>
  )
}
export default VideoContainer